import ModalComponent from './Modal';

const App = () => {
    return <div style={{width: '100%', height: '100%'}}>
        <ModalComponent>
        </ModalComponent>
    </div>
}

export default App;
